import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as api from 'api/meta.api';
import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';

export async function deleteSftpConnectionAction(
  dispatch: React.Dispatch<any>,
  user_name: string,
  token: string,
  translate: any
) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS,
    payload: { loading: true, user_name: user_name },
  });
  try {
    const deleteSftpUserConnectionFetch = await api.deleteSftpUserAPI({
      user_name,
      token,
    });
    if (deleteSftpUserConnectionFetch.response.status === 200) {
      msg.messageSuccess(translate('action_meta_delete-connection_success'));
      return dispatch({
        type: MetaTypes.DELETE_SFTP_CONNECTION,
        payload: user_name,
      });
    } else {
      msg.messageError(translate('action_meta_delete-connection_error'));
      notificationErr(metaError.delete_sftp_connection_002, translate);
    }
  } catch (error) {
    msg.messageError(translate('action_meta_delete-connection_error'));
    notificationErr(metaError.delete_sftp_connection_001, translate);
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS,
      payload: { loading: false, user_name: '' },
    });
  }
}

export async function createSftpConnectionAction(
  dispatch: React.Dispatch<any>,
  body: metaResponseInterface.TcreateSftpUserAPIBodyProps,
  token: string,
  translate: any
) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS,
    payload: true,
  });
  try {
    const createSftpUserConnectionFetch = await api.createSftpUserAPI({
      body,
      token,
    });

    if (createSftpUserConnectionFetch.response.status === 403) {
      msg.messageWarning(translate('action_meta_create-sftp-connection_error-403'));
      return false;
    }

    if (createSftpUserConnectionFetch.response.status === 200) {
      msg.messageSuccess(translate('action_meta_create-connection_success'));
      dispatch({
        type: MetaTypes.ADD_NEW_CONNECTION,
        payload: createSftpUserConnectionFetch.data,
      });
      return true;
    } else {
      msg.messageError(translate('action_meta_create-connection_error'));
      notificationErr(metaError.create_sftp_connection_002, translate);
      return false;
    }
  } catch (error) {
    msg.messageError(translate('action_meta_create-connection_error'));
    notificationErr(metaError.create_sftp_connection_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS,
      payload: false,
    });
  }
}
