import { useContext } from 'react';
import CatalogCsvUploadContext from 'contexts/sales/SalesContext';

export default function useContextSales() {
  const context = useContext(CatalogCsvUploadContext);
  if (context === undefined) {
    throw new Error('useContextSales must be used within a SalesProvider');
  }
  return context;
}
