import { memo, useCallback, useMemo, useState } from 'react';
import { ReferralProgramContext } from './ReferralProgramContext';
import { ChildrenProps } from 'interfaces/general.interface';

export type TReferralData = {
  senderName?: string;
  senderEmail?: string;
  senderClientName?: string;
  recipientName?: string;
  recipientEmail?: string;
  recipientTenantName?: string;
};

const ReferralProgramProvider = ({ children }: ChildrenProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState<TReferralData | null>(null);

  const setIsOpenModalAction = useCallback((isOpen: boolean) => {
    setIsOpenModal(isOpen);
  }, []);

  const setDataAction = useCallback((data: TReferralData | null) => {
    setData(data);
  }, []);

  const memoProvider = useMemo(
    () => ({
      isOpenModal,
      setIsOpenModalAction,
      data,
      setDataAction,
    }),
    [isOpenModal, setIsOpenModalAction, data, setDataAction]
  );

  return (
    <ReferralProgramContext.Provider value={memoProvider}>
      {children}
    </ReferralProgramContext.Provider>
  );
};

const MemoizedReferralProgramProvider = memo(ReferralProgramProvider);
export default MemoizedReferralProgramProvider;
