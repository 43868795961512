import * as MetaTypes from '../../meta.types';
import { IInitialMetaState } from 'interfaces/meta.interface';

export function handleSftp(state: IInitialMetaState, action: any): IInitialMetaState {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.SET_SFTP_CONNECTIONS: {
      const setSftpConnections: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          connections: payload,
        },
      };
      return setSftpConnections;
    }

    case MetaTypes.DELETE_SFTP_CONNECTION: {
      const deleteSftpConnection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          connections: state.sftp.connections.filter((item) => item.name !== payload),
        },
      };
      return deleteSftpConnection;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_SFPT_CONNECTIONS: {
      const setIsLoadingRemoveSftpConnection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          isLoadingRemoveSftpConnection: {
            loading: payload.loading,
            user_name: payload.user_name,
          },
        },
      };
      return setIsLoadingRemoveSftpConnection;
    }

    case MetaTypes.ADD_NEW_CONNECTION:
      const addNewSftpConnection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          connections: [payload, ...state.sftp.connections],
        },
      };
      return addNewSftpConnection;

    case MetaTypes.SET_IS_LOADING_CREATE_SFPT_CONNECTIONS: {
      const setIsLoadingCreateSftpConection: IInitialMetaState = {
        ...state,
        sftp: {
          ...state.sftp,
          isLoadingCreateSftpConnection: payload,
        },
      };
      return setIsLoadingCreateSftpConection;
    }

    default:
      return state;
  }
}
