import { IBrandUser } from 'interfaces/brandUsers.interface';
import {
  ISAdminClientState,
  IInitialSuperAdminState,
  ISAdminTenantState,
  IMapField,
  IShopifyQueryItem,
  IShopifyTemplateItem,
  IMapFieldTemplates,
} from 'interfaces/superAdmin.interface';

const initialSuperAdminState: IInitialSuperAdminState = {
  wholesaleClients: [] as ISAdminClientState[],
  brandClients: [] as ISAdminTenantState[],
  removeUserClient: (clientId: string, userId: string) => {},
  addUserClient: (clientId: string, userId: IBrandUser) => {},
  getConnectors: async () => Promise.resolve(false),
  connectors: {
    shopify: [],
    sftp: [],
  },
  isLoadingConnectors: false,
  // Map fields
  getMapFields: () => Promise.resolve(false),
  isLoadingMapFields: false,
  mapFields: [] as IMapField[],
  createMapField: () => Promise.resolve(false),
  isLoadingCreateMapField: false,
  deleteMapField: () => Promise.resolve(false),
  isLoadingDeleteMapField: {
    loading: false,
    mapFieldId: '',
  },
  // Map fields templates
  getMapFieldsTemplates: () => Promise.resolve(false),
  isLoadingMapFieldsTemplates: false,
  mapFieldsTemplates: [] as Array<IMapFieldTemplates>,
  createMapFieldsTemplate: () => Promise.resolve(false),
  isLoadingCreateMapFieldsTemplate: false,
  deleteMapFieldTemplate: () => Promise.resolve(false),
  isLoadingDeleteMapFieldTemplate: {
    loading: false,
    templateName: '',
  },
  shopify: {
    // Queries
    getShopifyQueries: () => Promise.resolve(false),
    isLoadingQueries: false,
    queries: [] as IShopifyQueryItem[],
    selectShopifyQuery: () => {},
    querySelected: {} as IShopifyQueryItem,
    createShopifyQuery: () => Promise.resolve(false),
    isLoadingCreateQuery: false,
    deleteShopifyQuery: () => Promise.resolve(false),
    isLoadingDeleteQuery: {
      loading: false,
      queryId: '',
    },
    updateShopifyQuery: () => Promise.resolve(false),
    isLoadingUpdateQuery: false,
    // Templates
    getShopifyTemplates: () => Promise.resolve(false),
    templates: [] as IShopifyTemplateItem[],
    isLoadingTemplates: false,
    selectShopifyTemplate: () => {},
    templateSelected: {} as IShopifyTemplateItem,
    createUpdateShopifyTemplate: () => Promise.resolve(false),
    isLoadingCreateUpdateTemplate: false,
    deleteShopifyTemplate: () => Promise.resolve(false),
    isLoadingDeleteTemplate: {
      loading: false,
      templateTag: '',
    },
  },
};

export default initialSuperAdminState;
