import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as api from 'api/meta.api';
import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';
import { IShopifyQueryItem } from 'interfaces/superAdmin.interface';
import { TupdateShopifyConnectionAPIBodyUpdateProps } from 'api/responseInterfaces/superAdmin.response.interface';

export async function createShopifyConnectionAction({
  dispatch,
  body,
  queries,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: metaResponseInterface.TcreateShopifyConnectionAPIBodyProps;
  queries: IShopifyQueryItem[];
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS, payload: true });
  try {
    const createShopifyConnectionFetch = await api.createShopifyConnectionAPI({
      body,
      token,
    });
    if (createShopifyConnectionFetch.response.status === 409) {
      msg.messageWarning(translate('action_meta_create-shopify-connection_error-409'));
      return false;
    }

    if (
      createShopifyConnectionFetch.response.status === 200 ||
      createShopifyConnectionFetch.response.status === 201
    ) {
      const findShopQuery = queries.find((query) => query.tag === 'shop');
      if (!findShopQuery) {
        msg.messageWarning("No se encontró la query 'shop'");
        return false;
      }
      try {
        const createUpdateConfigurationFetch =
          await api.createUpdateShopifyConfigurationAPI({
            connector_id: createShopifyConnectionFetch.data.id,
            queries: [findShopQuery.id],
            token,
          });
        if (
          createUpdateConfigurationFetch.response.status === 200 ||
          createUpdateConfigurationFetch.response.status === 201
        ) {
          const config = {
            id: createUpdateConfigurationFetch.data.data[0].id,
            connector_id: createUpdateConfigurationFetch.data.data[0].connector_id,
            queries: createUpdateConfigurationFetch.data.data.map(
              (item) => item.query_id
            ),
            createdAt: createUpdateConfigurationFetch.data.data[0].createdAt,
            updatedAt: createUpdateConfigurationFetch.data.data[0].updatedAt,
          };
          dispatch({
            type: MetaTypes.CREATE_UPDATE_CONFIGURATION,
            payload: config,
          });

          const testConnection = await api.testShopifyConnectionAPI({
            token,
            connectorId: createShopifyConnectionFetch.data.id,
          });

          const newConnection: metaInterface.TShopifyConnectionItem = {
            ...createShopifyConnectionFetch.data,
            type: 'shopify',
            error: testConnection.response.status !== 200,
            shopify_connector_maps:
              createShopifyConnectionFetch.data?.shopify_connector_maps?.map(
                (item) => item.id
              ) ?? [],
          };
          dispatch({
            type: MetaTypes.ADD_NEW_SHOPIFY_CONNECTION,
            payload: newConnection,
          });

          msg.messageSuccess('Conexión creada con éxito');
          return true;
        }
        msg.messageError('Error al guardar la configuración');
        notificationErr(metaError.create_shopify_connection_004, translate);
        return false;
      } catch (error) {
        console.log('error', error);

        msg.messageError('Error del servidor al guardar la configuración');
        notificationErr(metaError.create_shopify_connection_003, translate);
        return false;
      } finally {
        dispatch({
          type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION,
          payload: false,
        });
      }
    }
    msg.messageError(translate('action_meta_create-shopify-connection_error'));
    notificationErr(metaError.create_shopify_connection_002, translate);
    return false;
  } catch (err) {
    notificationErr(metaError.create_shopify_connection_001, translate);
    msg.messageError(translate('action_meta_create-shopify-connection_error'));
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS,
      payload: false,
    });
  }
}

export async function updateShopifyConnectionAction({
  dispatch,
  body,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: TupdateShopifyConnectionAPIBodyUpdateProps;
  connectionId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS,
    payload: true,
  });
  try {
    const updateShopifyConnectionFetch = await api.updateShopifyConnectionAPI({
      body,
      connectionId,
      token,
    });
    if (updateShopifyConnectionFetch.response.status === 200) {
      const testConnection = await api.testShopifyConnectionAPI({
        token,
        connectorId: connectionId,
      });
      const updatedConnection = {
        ...body,
        error: testConnection.response.status !== 200,
      };
      msg.messageSuccess(translate('action_meta_update-connection_success'));
      dispatch({
        type: MetaTypes.UPDATE_SHOPIFY_CONNECTION,
        payload: {
          id: connectionId,
          data: updatedConnection,
        },
      });
      return true;
    } else {
      msg.messageError(translate('action_meta_update-connection_error'));
      notificationErr(metaError.update_shopify_connection_002, translate);
      return false;
    }
  } catch (error) {
    msg.messageError(translate('action_meta_update-connection_error'));
    notificationErr(metaError.update_shopify_connection_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS,
      payload: false,
    });
  }
}

export async function deleteShopifyConnectionAction({
  dispatch,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectionId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION,
    payload: { loading: true, id: connectionId },
  });
  try {
    const removeShopifyConnectionFetch = await api.removeShopifyConnectionAPI({
      connectionId,
      token,
    });
    if (removeShopifyConnectionFetch.response.status === 200) {
      msg.messageSuccess(translate('action_meta_delete-connection_success'));
      dispatch({
        type: MetaTypes.DELETE_SHOPIFY_CONNECTION,
        payload: connectionId,
      });
      return true;
    } else {
      msg.messageError(translate('action_meta_delete-connection_error'));
      notificationErr(metaError.delete_shopify_connection_002, translate);
      return false;
    }
  } catch (error) {
    msg.messageError(translate('action_meta_delete-connection_error'));
    notificationErr(metaError.delete_shopify_connection_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION,
      payload: { loading: false, id: '' },
    });
  }
}

// Configuration Shopify
export async function getShopifyConfigurationAction({
  dispatch,
  connectorId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorId: string;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CONFIGURATION, payload: true });
  try {
    const getConfigurationFetch = await api.getShopifyConnectorConfigurationAPI({
      connectorId,
      token,
    });
    if (getConfigurationFetch.response.status === 200) {
      const config = {
        id: getConfigurationFetch.data[0].id,
        connector_id: getConfigurationFetch.data[0].connector_id,
        queries: getConfigurationFetch.data.map((item) => item.query_id),
        createdAt: getConfigurationFetch.data[0].createdAt,
        updatedAt: getConfigurationFetch.data[0].updatedAt,
      };
      dispatch({
        type: MetaTypes.SET_CONFIGURATION,
        payload: config,
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener la configuración');
    notificationErr(metaError.get_shopify_configuration_001, translate);
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_CONFIGURATION, payload: false });
  }
}

export async function createUpdateShopifyConfigurationAction({
  dispatch,
  connector_id,
  queries,
  configuration,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connector_id: string;
  queries: string[];
  configuration: metaInterface.IShopyfyConfigurationItem;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION, payload: true });
  try {
    const createUpdateConfigurationFetch = await api.createUpdateShopifyConfigurationAPI({
      connector_id,
      queries,
      token,
    });
    if (
      createUpdateConfigurationFetch.response.status === 200 ||
      createUpdateConfigurationFetch.response.status === 201
    ) {
      const config = {
        id: createUpdateConfigurationFetch.data.data[0].id,
        connector_id: createUpdateConfigurationFetch.data.data[0].connector_id,
        queries: createUpdateConfigurationFetch.data.data.map((item) => item.query_id),
        createdAt: createUpdateConfigurationFetch.data.data[0].createdAt,
        updatedAt: createUpdateConfigurationFetch.data.data[0].updatedAt,
      };
      dispatch({
        type: MetaTypes.CREATE_UPDATE_CONFIGURATION,
        payload: config,
      });
      msg.messageSuccess('Configuración guardada con éxito');
      return true;
    } else if (createUpdateConfigurationFetch.response.status === 202) {
      msg.messageSuccess('Configuración guardada con éxito');
      dispatch({
        type: MetaTypes.CREATE_UPDATE_CONFIGURATION,
        payload: { ...configuration, queries: [] },
      });
      return true;
    }
    msg.messageError('Error al guardar la configuración');
    notificationErr(metaError.create_update_shopify_configuration_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al guardar la configuración');
    notificationErr(metaError.create_update_shopify_configuration_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION,
      payload: false,
    });
  }
}
