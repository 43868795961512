import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as api from 'api/meta.api';
import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';

export async function getMapsAction({
  dispatch,
  connectorType,
  clientId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType: string;
  clientId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_MAPS,
    payload: true,
  });
  try {
    const getMapsFetch = await api.getMapsAPI({ clientId, token });
    if (getMapsFetch.response.status === 200 || getMapsFetch.response.status === 201) {
      dispatch({
        type:
          connectorType === 'shopify'
            ? MetaTypes.GET_SHOPIFY_MAPS
            : MetaTypes.GET_SHOPIFY_MAPS,
        payload: getMapsFetch.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener los mapas');
    notificationErr(metaError.get_maps_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_MAPS,
      payload: false,
    });
  }
}

export async function createMapAction({
  dispatch,
  connectorType,
  body,
  connection,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType: string;
  body: metaResponseInterface.TcreateMapBodyProps;
  connection: metaInterface.TSftpUserItem | metaInterface.TShopifyConnectionItem;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_CREATE_MAP,
    payload: true,
  });
  try {
    const createMapFetch = await api.createMapAPI({ body, token });
    if (
      createMapFetch.response.status === 200 ||
      createMapFetch.response.status === 201
    ) {
      // Add map to conector
      const maps = [
        ...(connection as metaInterface.TShopifyConnectionItem).shopify_connector_maps,
        createMapFetch.data.id,
      ];
      try {
        const addMapToConnector = await api.addMapToConnectorAPI({
          connectorId: connection.id,
          maps,
          token,
        });
        if (
          addMapToConnector.response.status === 200 ||
          addMapToConnector.response.status === 201
        ) {
          dispatch({
            type:
              connectorType === metaInterface.connectorTypes.shopify
                ? MetaTypes.CREATE_SHOPIFY_MAP
                : MetaTypes.CREATE_SHOPIFY_MAP,
            payload: { ...createMapFetch.data, shopify_connector_maps: maps },
          });
          dispatch({
            type:
              connectorType === metaInterface.connectorTypes.shopify
                ? MetaTypes.UPDATE_SHOPIFY_CONNECTION
                : MetaTypes.UPDATE_SHOPIFY_CONNECTION,
            payload: {
              id: connection.id,
              data: {
                ...connection,
                shopify_connector_maps: maps,
              },
            },
          });
          msg.messageSuccess('Mapa creado con éxito y añadido al conector');
          return true;
        }
        dispatch({
          type:
            connectorType === metaInterface.connectorTypes.shopify
              ? MetaTypes.CREATE_SHOPIFY_MAP
              : MetaTypes.CREATE_SHOPIFY_MAP,
          payload: createMapFetch.data,
        });
        msg.messageSuccess(
          'Mapa creado con éxito pero no se ha podido asociar al conector'
        );
        notificationErr(metaError.create_map_004, translate);
        return false;
      } catch (error) {
        msg.messageError('Error del servidor al añadir el mapa al conector');
        notificationErr(metaError.create_map_003, translate);
        return false;
      }
    }
    msg.messageError('Error al crear el mapa');
    notificationErr(metaError.create_map_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al crear el mapa');
    notificationErr(metaError.create_map_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_MAP,
      payload: false,
    });
  }
}

export async function updateMapAction({
  dispatch,
  connectorType,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType: string;
  body: metaResponseInterface.TupdateMapBodyProps;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_UPDATE_MAP,
    payload: true,
  });
  try {
    const createMapFetch = await api.updateMapAPI({ body, token });
    if (
      createMapFetch.response.status === 200 ||
      createMapFetch.response.status === 201
    ) {
      dispatch({
        type:
          connectorType === metaInterface.connectorTypes.shopify
            ? MetaTypes.UPDATE_SHOPIFY_MAP
            : MetaTypes.UPDATE_SHOPIFY_MAP,
        payload: {
          id: body.filter.id,
          data: body.update,
        },
      });
      msg.messageSuccess('Mapa actualizado con éxito');
      return true;
    }
    msg.messageError('Error al actualizar el mapa');
    notificationErr(metaError.update_map_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al actualizar el mapa');
    notificationErr(metaError.update_map_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_UPDATE_MAP,
      payload: false,
    });
  }
}

export async function addMapToConnectorAction({
  dispatch,
  connectorType,
  mapIds,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType: string;
  mapIds: string[];
  connectionId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_ADD_MAP_TO_CONNECTION,
    payload: true,
  });
  try {
    const addMapToConnectorFetch = await api.addMapToConnectorAPI({
      connectorId: connectionId,
      maps: mapIds,
      token,
    });
    if (
      addMapToConnectorFetch.response.status === 200 ||
      addMapToConnectorFetch.response.status === 201
    ) {
      dispatch({
        type:
          connectorType === metaInterface.connectorTypes.shopify
            ? MetaTypes.UPDATE_SHOPIFY_CONNECTION
            : MetaTypes.UPDATE_SHOPIFY_CONNECTION,
        payload: {
          id: connectionId,
          data: { shopify_connector_maps: mapIds },
        },
      });
      msg.messageSuccess('Mapa añadido al conector con éxito');
      return true;
    }
    msg.messageError('Error al añadir el mapa al conector');
    notificationErr(metaError.add_map_to_connector_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al añadir el mapa al conector');
    notificationErr(metaError.add_map_to_connector_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_ADD_MAP_TO_CONNECTION,
      payload: false,
    });
  }
}

export async function deleteMapFromConnectorAction({
  dispatch,
  connectorType,
  mapIds,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType: string;
  mapIds: string[];
  connectionId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION,
    payload: true,
  });
  try {
    const deleteMapFromConnectorFetch = await api.addMapToConnectorAPI({
      connectorId: connectionId,
      maps: mapIds,
      token,
    });
    if (
      deleteMapFromConnectorFetch.response.status === 200 ||
      deleteMapFromConnectorFetch.response.status === 201
    ) {
      dispatch({
        type:
          connectorType === metaInterface.connectorTypes.shopify
            ? MetaTypes.UPDATE_SHOPIFY_CONNECTION
            : MetaTypes.UPDATE_SHOPIFY_CONNECTION,
        payload: {
          id: connectionId,
          data: { shopify_connector_maps: mapIds },
        },
      });
      msg.messageSuccess('Mapa eliminado del conector con éxito');
      return true;
    }
    msg.messageError('Error al eliminar el mapa del conector');
    notificationErr(metaError.delete_map_from_connector_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar el mapa del conector');
    notificationErr(metaError.delete_map_from_connector_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION,
      payload: false,
    });
  }
}
