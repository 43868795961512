import { IInitialMetaState } from 'interfaces/meta.interface';
import { handleLoadingStates } from './switchs/handleLoadingStates';
import { handleSftp } from './switchs/handleSftp';
import { handleShopify } from './switchs/handleShopify';

export default function metaReducer(state: IInitialMetaState, action: any) {
  state = handleLoadingStates(state, action);
  state = handleSftp(state, action);
  state = handleShopify(state, action);

  return state;
}
