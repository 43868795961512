import * as metaInterface from 'interfaces/meta.interface';

const initialMetaState: metaInterface.IInitialMetaState = {
  getConnections: () => Promise.resolve(false),
  isLoadingConnections: false,
  getMaps: () => Promise.resolve(false),
  isLoadingMaps: false,
  createMap: () => Promise.resolve(false),
  isLoadingCreateMap: false,
  updateMap: () => Promise.resolve(false),
  isLoadingUpdateMap: false,
  addMapToConnection: () => Promise.resolve(false),
  isLoadingAddMapToConnection: false,
  deleteMapFromConnection: () => Promise.resolve(false),
  isLoadingDeleteMapFromConnection: false,
  sftp: {
    connections: [],
    createSftpConnection: () => Promise.resolve(false),
    isLoadingCreateSftpConnection: false,
    removeSftpConnection: () => {},
    isLoadingRemoveSftpConnection: {
      loading: false,
      user_name: '',
    },
  },
  shopify: {
    // Connections
    connections: [] as metaInterface.TShopifyConnectionItem[],
    connectionSelected: {} as metaInterface.TShopifyConnectionItem,
    createShopifyConnection: () => Promise.resolve(false),
    isLoadingCreateShopifyConnection: false,
    updateShopifyConnection: () => Promise.resolve(false),
    isLoadingUpdateShopifyConnection: false,
    removeShopifyConnection: () => Promise.resolve(false),
    isLoadingRemoveShopifyConnection: {
      loading: false,
      connection_id: '',
    },
    // Mappings
    mappings: [] as metaInterface.IMap[],
    // Configuration
    configuration: {} as metaInterface.IShopyfyConfigurationItem,
    getShopifyConfiguration: () => Promise.resolve(false),
    isLoadingConfiguration: false,
    createUpdateShopifyConfiguration: () => Promise.resolve(false),
    isLoadingCreateUpdateConfiguration: false,
  },
};

export default initialMetaState;
