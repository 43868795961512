export const SET_IS_LOADING_CONNECTIONS = 'SET_IS_LOADING_CONNECTIONS';
// MAP
export const SET_IS_LOADING_MAPS = 'SET_IS_LOADING_MAPS';
export const SET_IS_LOADING_CREATE_MAP = 'SET_IS_LOADING_CREATE_MAP';
export const SET_IS_LOADING_UPDATE_MAP = 'SET_IS_LOADING_UPDATE_MAP';
export const SET_IS_LOADING_ADD_MAP_TO_CONNECTION = 'SET_IS_LOADING_ADD_MAP_TO_CONNECTION';
export const SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION = 'SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION';
// SFTP
export const SET_SFTP_CONNECTIONS = 'SET_SFTP_CONNECTIONS';
export const DELETE_SFTP_CONNECTION = 'DELETE_SFTP_CONNECTION';
export const SET_IS_LOADING_DELETE_SFPT_CONNECTIONS = 'SET_IS_LOADING_DELETE_SFPT_CONNECTIONS';
export const ADD_NEW_CONNECTION = 'ADD_NEW_CONNECTION';
export const SET_IS_LOADING_CREATE_SFPT_CONNECTIONS = 'SET_IS_LOADING_CREATE_SFPT_CONNECTIONS';
// SHOPIFY
// Shopify queries
export const SET_QUERIES = 'SET_QUERIES';
export const SET_IS_LOADING_QUERIES = 'SET_IS_LOADING_QUERIES';
// Maps
export const GET_SHOPIFY_MAPS = 'GET_SHOPIFY_MAPS';
export const CREATE_SHOPIFY_MAP = 'CREATE_SHOPIFY_MAP';
export const UPDATE_SHOPIFY_MAP = 'UPDATE_SHOPIFY_MAP';
// Connections
export const SET_SHOPIFY_CONNECTIONS = 'SET_SHOPIFY_CONNECTIONS';
export const DELETE_SHOPIFY_CONNECTION = 'DELETE_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION = 'SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION';
export const ADD_NEW_SHOPIFY_CONNECTION = 'ADD_NEW_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS = 'SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS';
export const UPDATE_SHOPIFY_CONNECTION = 'UPDATE_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS = 'SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS';
// Configuration
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_IS_LOADING_CONFIGURATION = 'SET_IS_LOADING_CONFIGURATION';
export const CREATE_UPDATE_CONFIGURATION = 'CREATE_UPDATE_CONFIGURATION';
export const SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION = 'SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION';