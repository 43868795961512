import * as MetaTypes from '../../meta.types';
import { IInitialMetaState } from 'interfaces/meta.interface';

export function handleShopify(state: IInitialMetaState, action: any): IInitialMetaState {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.GET_SHOPIFY_MAPS: {
      const getMaps: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          mappings: payload,
        },
      };
      return getMaps;
    }

    case MetaTypes.CREATE_SHOPIFY_MAP: {
      const createMap: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          mappings: [payload, ...state.shopify.mappings],
        },
      };
      return createMap;
    }

    case MetaTypes.UPDATE_SHOPIFY_MAP: {
      const updateMap: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          mappings: state.shopify.mappings.map((item) =>
            item.id === payload.id ? { ...item, ...payload.data } : item
          ),
        },
      };
      return updateMap;
    }

    case MetaTypes.SET_SHOPIFY_CONNECTIONS: {
      const setShopifyConnections: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: payload,
        },
      };
      return setShopifyConnections;
    }

    case MetaTypes.ADD_NEW_SHOPIFY_CONNECTION: {
      const addNewShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: [payload, ...state.shopify.connections],
          connectionSelected: payload,
        },
      };
      return addNewShopifyConnection;
    }

    case MetaTypes.UPDATE_SHOPIFY_CONNECTION: {
      const updateShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: state.shopify.connections.map((item) =>
            item.id === payload.id ? { ...item, ...payload.data } : item
          ),
          connectionSelected: { ...state.shopify.connectionSelected, ...payload.data },
        },
      };
      return updateShopifyConnection;
    }

    case MetaTypes.SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS: {
      const setIsLoadingUpdateShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingUpdateShopifyConnection: payload,
        },
      };
      return setIsLoadingUpdateShopifyConnection;
    }

    case MetaTypes.DELETE_SHOPIFY_CONNECTION: {
      const deleteShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: state.shopify.connections.filter((item) => item.id !== payload),
        },
      };
      return deleteShopifyConnection;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION: {
      const setIsLoadingRemoveShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingRemoveShopifyConnection: {
            loading: payload.loading,
            connection_id: payload.id,
          },
        },
      };
      return setIsLoadingRemoveShopifyConnection;
    }

    // Configuration
    case MetaTypes.SET_CONFIGURATION: {
      const setConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          configuration: payload,
        },
      };
      return setConfiguration;
    }

    case MetaTypes.SET_IS_LOADING_CONFIGURATION: {
      const setIsLoadingConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingConfiguration: payload,
        },
      };
      return setIsLoadingConfiguration;
    }

    case MetaTypes.CREATE_UPDATE_CONFIGURATION: {
      const createUpdateConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          configuration: payload,
        },
      };
      return createUpdateConfiguration;
    }

    case MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION: {
      const setIsLoadingCreateUpdateConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingCreateUpdateConfiguration: payload,
        },
      };
      return setIsLoadingCreateUpdateConfiguration;
    }

    default:
      return state;
  }
}
