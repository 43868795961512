import * as MetaTypes from '../../meta.types';
import { IInitialMetaState } from 'interfaces/meta.interface';

export function handleLoadingStates(
  state: IInitialMetaState,
  action: any
): IInitialMetaState {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.SET_IS_LOADING_CONNECTIONS: {
      const setConnections: IInitialMetaState = {
        ...state,
        isLoadingConnections: payload,
      };
      return setConnections;
    }

    case MetaTypes.SET_IS_LOADING_MAPS: {
      const setIsLoadingMaps: IInitialMetaState = {
        ...state,
        isLoadingMaps: payload,
      };
      return setIsLoadingMaps;
    }

    case MetaTypes.SET_IS_LOADING_CREATE_MAP: {
      const setIsLoadingCreateMap: IInitialMetaState = {
        ...state,
        isLoadingCreateMap: payload,
      };
      return setIsLoadingCreateMap;
    }

    case MetaTypes.SET_IS_LOADING_UPDATE_MAP: {
      const setIsLoadingUpdateMap: IInitialMetaState = {
        ...state,
        isLoadingUpdateMap: payload,
      };
      return setIsLoadingUpdateMap;
    }

    case MetaTypes.SET_IS_LOADING_ADD_MAP_TO_CONNECTION: {
      const setIsLoadingAddMapToConnection: IInitialMetaState = {
        ...state,
        isLoadingAddMapToConnection: payload,
      };
      return setIsLoadingAddMapToConnection;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION: {
      const setIsLoadingDeleteMapFromConnection: IInitialMetaState = {
        ...state,
        isLoadingDeleteMapFromConnection: payload,
      };
      return setIsLoadingDeleteMapFromConnection;
    }

    default:
      return state;
  }
}
